import React from "react";
import const_image2 from "../Assets/img/con2.jpg";
import hero_video from "../Assets/video/hero_video.mp4";
import "../Assets/css/header.css";

export default function Header() {
  return (
    <div className="row">
      <div className="col">
        <div className="col-content-third-row">
          <h1>קבוצת ס.א.ד בע"מ</h1>
          <h3>515861862 .ח.פ</h3>
          <h4>קבלן רשום בסיווג 100 ג1</h4>
          <h4>מספר קבלן (רישיון):33404</h4>
        </div>
        <video
          autoPlay
          muted
          loop
          alt="hero-img"
          className="hero-section-img video"
          poster={const_image2}
          playsInline
        >
          <source type="video/mp4" src={hero_video} />
        </video>
        <img className="hero-section-img img" src={const_image2} alt="" />
      </div>
    </div>
  );
}
