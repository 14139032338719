import React from "react";
import Landing from "./Components/Landing";

function App() {
  return (
    <>
      <Landing />
    </>
  );
}

export default App;
